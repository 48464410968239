body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  margin: 30px 40px;
  padding :20px
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiChip-colorPrimary {
  color: #231f20 !important;
  background-color: #FFFFFF !important;
}

.MuiChip-root .MuiChip-avatarColorPrimary,
.MuiButton-containedPrimary {
  background: linear-gradient(315deg, #ffd200 18.29%, #FFF229 83.33%);
  color: #231f20 !important;
  -webkit-box-shadow: 0 4px 12px rgba(204, 168, 0, 0.32) !important;
  box-shadow: 0 4px 12px rgba(204, 168, 0, 0.32) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}

.Mui-focused fieldset {
  border: 1px solid #FFD200 !important;
}

.MuiFormHelperText-root {
  color: #f44336;
}

.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0.5px rgba(0,0,0,0.12), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
}

.MuiTableCell-head {
  border: 1px solid rgba(224, 224, 224, 1);
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiButton-root {
  cursor: pointer;
}

.MuiButton-containedSecondary:hover {
  background-color: #f5f5f5 !important;
}

.MuiInputBase-formControl {
  margin-right: 5px;
  margin-bottom: 5px;

}

.MuiOutlinedInput-input {
    /* padding: 10px ; */
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background:rgb(255 242 41);
  border-color:rgb(255 242 41);
  color: #000;

}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
 /* display: none; */
     box-shadow: -10px 0 0 rgb(255 242 41);
}

@media screen and (max-width:1024px)
{

.container {
  margin: 20px 10px;
  padding :20px
}

}